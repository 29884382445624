<template>
    <section :id="id" :class="class" class="pt-5">
        <div class="container">
            <TitleComponent v-if="title" :title="title" />
            <h4>{{ subtitle }}</h4>
            <p v-if="text" class="text-section" v-html="text"></p>
            <slot></slot>
            <p v-if="footer" v-html="footer"></p>
        </div>
    </section>
</template>

<script>
import TitleComponent from './TitleComponent.vue'

export default{
    name: 'SectionComponent',
    components:{
        TitleComponent
    },
    props: {
        'title':{
            type: String
        },
        'text':{
            type: String
        },
        'id':{
            type: String
        },
        'class':{
            type: String
        },
        'subtitle':{
            type: String
        },
        'footer':{
            type: String
        },
        'backgroundimage':{
            type: String
        }
    }
}
</script>

<style scoped>
.text-section:deep(*){
    font-family: 'Anantason-Condensed'; 
    color: black;
    font-size: 1.5rem;
}
</style>
