<template>
  <template v-if="home">
    <section id="hero" class="pt-0 px-4">
      <video class="background-video px-4" @loadedmetadata="videoLoaded" playsinline autoplay="autoplay" loop="true" muted="true" poster="" preload="auto" alt="slidebg1" data-bgposition="center top" data-bgfit="cover" data-bgrepeat="no-repeat">
        <source :src="'/videos/inicio/' + home.site_inicio.video" type="video/mp4">
      </video>
      <div v-if="home.site_inicio_accesos && home.site_inicio_accesos.length" class="container-boxes">
        <div v-for="item_acceso of home.site_inicio_accesos" v-bind:key="item_acceso.id_acceso" class="box d-flex flex-column justify-content-between">
          <div class="circle">{{ selectedInitials(item_acceso.titulo) }}</div>
          <div>
            <div class="box-text text-uppercase">{{ item_acceso.titulo }}</div>
            <div class="small-text">
              <a :href="isURL(item_acceso.link) ? item_acceso.link : item_acceso.link"  :target="isURL(item_acceso.link) ? '_BLANK' : ''" >Ingresar ></a>
            </div>
          </div>
        </div>
      </div>
    </section>

    <main id="main">

      <!-- ======= About Us Section ======= -->
      <SectionComponent v-if="home?.site_inicio" :id="'about'" :class="'about'">
        <div class="inscripciones row">
          <div class="col-12 col-sm-12 col-md-6 d-flex align-items-center">
            <h1 class="text-center w-100">{{ home.site_inicio.accesos_titulo }}</h1>
          </div>
          <div class="col-12 col-sm-12 col-md-6">
            <div class="recuadro mx-5">
              <div class="text text-center py-3 px-4 mx-5 mx-md-0 mx-lg-5">{{ home.site_inicio.accesos_texto }}</div>
              <div class="bottom text-center mx-5 py-3 mx-md-0 mx-lg-5">
                <router-link to="/contacto" class="my-4">CONTACTO</router-link>
              </div>
            </div>
            
          </div>
        </div>
      </SectionComponent>
      <!-- End About Us Section -->

      <!-- ======= Cta Section ======= -->
      <SectionComponent v-if="home?.site_novedades" :title="home.site_inicio.novedades_titulo" text="">
        <div class="container">
          <div v-if="home.site_novedades_areas" class="noticias-filters text-center mb-5">
            <a class="badge me-1" :class="{ 'active' : filterNoticiaSel == null}" @click.stop="filterNoticia()">Todas</a>
            <a v-for="area in home.site_novedades_areas" key="site_novedades_areas.id_area" class="badge me-1" :class="{ 'active' : filterNoticiaSel == area.id_area }" @click.stop="filterNoticia(area.id_area)">{{ area.area }}</a>
          </div>
          <NoticiasComponent v-if="filteredNovedades.length" :noticias="filteredNovedades"/>
          <div v-else class="text-center alert alert-light" role="alert">No hay novedades para el filtro seleccionado</div>
          <div class="d-flex justify-content-center">
            <!-- ButtonComponent :href="'/novedades/'" :caption="'Ver todas las novedades...'" :hollow="true"/ -->
          </div>
        </div>
      </SectionComponent>

      <div v-if="home?.site_inicio" class="cv text-center mb-5">
        <h3>¿Querés formar parte de nuestro equipo?</h3>
        <h3 class="mb-4"><strong>ENVIANOS TU CV</strong></h3>
        <router-link to="/cv" class="my-4"><h5>CARGAR</h5></router-link>
      </div>
    </main><!-- End #main -->	
  </template>
</template>

<script>
import { ref, onMounted, computed } from 'vue'
import { useStore } from 'vuex'
import MesComponent from '../components/general/MesComponent.vue'
import TitleComponent from '../components/general/TitleComponent.vue'
import SectionComponent from '../components/general/SectionComponent.vue'
import ButtonComponent from '../components/general/ButtonComponent.vue'
import NoticiasComponent from '../components/noticias/NoticiasComponent.vue'
import NoticiasDestacadasComponent from '../components/noticias/NoticiasDestacadasComponent.vue'
import EventosComponent from '../components/eventos/EventosComponent.vue'
import GoogleMapsComponent from '../components/general/GoogleMapsComponent.vue'
import DireccionesComponent from '../components/general/DireccionesComponent.vue'
import FormConsultaComponent from '../components/general/FormConsultaComponent.vue'
import AlbumComponent from '../components/general/AlbumComponent.vue'

export default {
  name: 'HomeView',
  components: {
    MesComponent,
    TitleComponent,
    SectionComponent,
    ButtonComponent,
    NoticiasComponent,
    NoticiasDestacadasComponent,
    EventosComponent,
    GoogleMapsComponent,
    DireccionesComponent,
    FormConsultaComponent,
    AlbumComponent
  },
  setup(){
    //const home = computed(() => store.state.home)
    // Define a reactive variable to store your data
    const home = ref(null)
    const store = useStore()
    const filterNoticiaSel = ref(null)
    
    const videoLoaded = () => {
      store.commit('setLoading', false)
    }
    const fetchHome = async () => {
      store.commit('setLoading', true)
      try {
        const response = await fetch('home', { 'auth': true })
        const responseData = await response.json()
        // Assign the fetched data to the reactive variable
        home.value = responseData
      } catch (error) {
        console.error('Error fetching data:', error)
      } finally {
        store.commit('setLoading', false)
      }
    }

    const selectedInitials = (text) => {
      const words = text.split(' ');
      const firstTwoWords = words.slice(0, 2);
      return firstTwoWords.map(word => word.charAt(0)).join('');
    }
          

    // Call the fetchData function when the component is mounted
    onMounted(() => {
      store.commit('setLoading', true)
      fetchHome()
    })

    const filteredNovedades = computed(() => {
      return home.value.site_novedades?.filter(item => (item['id_area'] == filterNoticiaSel.value || filterNoticiaSel.value == null))
    })

    const isURL = (str) => {
      return (str.indexOf("http://") == 0 || str.indexOf("https://") == 0)
    }
    
    const filterNoticia = (area = null) => {
      filterNoticiaSel.value = area
    }

    return {home, isURL, videoLoaded, selectedInitials, filteredNovedades, filterNoticia, filterNoticiaSel}
  }

}
</script>

<style scoped>

#hero {
  font-family: 'Montserrat';
}

.background-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Optional: to darken the video */
}
.container-boxes {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  align-items: flex-end;
  width: 100%;
  z-index: 1;
  padding-bottom: 20px; /* Adjust as needed */
}
.box {
  width: 12vw;
  height: 188px;
  background-color: white;
  margin: 18px 16px 36px 16px;

  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}
.circle {
  width: 115px;
  height: 115px;
  background-color: blue;
  border-radius: 50%;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 40px;
  font-weight: lighter;
  font-family: 'Roboto Condensed';
}
.box-text {
  text-align: center;
  text-transform: uppercase;
}
.small-text {
  text-align: center;
  font-size: 12px;
  color: gray;
  text-transform: uppercase;
}

.noticias-filters a {
  font-family: "Anantason-Regular";
  font-size: 1.1em;
  color:rgb(17, 49, 97);
  cursor: pointer;
}
.noticias-filters a.active {
  background-color: #d8e0eb!important;
}

.noticias-filters a:hover {
  color:rgb(17, 49, 97);
  background-color: #d8e0eb!important;
}

@media (max-width: 1600px) {
  .box {
    width: 13vw;
    height: 195px;
  }
  .circle {
    width: 93px;
    height: 93px;
    font-size: 2.5em;
  }
}

/*@media (max-width: 1280px) {
  .box {
    width: 18vw;
    height: 18vw;
  }
  .circle {
    width: 8vw;
    height: 8vw;
    font-size: 2.5em;
  }
}
*/
@media (max-width: 1024px) {
  .box {
    width: 17vw;
    height: 188px;
  }
  .circle {
    width: 83px;
    height: 83px;
    font-size: 2.5em;
  }
  #hero {
    height: 52vw;
  }
  #hero video{
    height: 52vw;
  }
}

@media (max-width: 768px) {
  .box {
    width: 19vw;
    height: 168px;
    margin: 18px 6px 36px 6px;
    font-size: 0.8em;    
  }
  
  .circle {
    width: 80px;
    height: 80px;
    font-size: 2.2em;
  }  
}
@media (max-width: 640px) {
  .box {
    width: 25vw;
    height: 150px;
  }
  
  .circle {
    width: 60px;
    height: 60px;
    font-size: 2.2em;
  }
}
@media (max-width: 480px) {
  .box {
    width: 25vw;
    height: 30vw;
    margin: 18px 6px 36px 6px;
    font-size: 0.8em;    
  }
  
  .circle {
    width: 11vw;
    height: 11vw;
    font-size: 2.2em;
  }

  #hero {
    height: 72vw;
  }
  #hero video{
    height: 72vw;
  }
}
</style>