<template>
    <header id="header" class="pickgradient">
        <div class="container-fluid">
            <div class="d-flex justify-content-lg-between">
                <router-link to="/" class="logo logo-blue me-auto me-lg-0">
                    <img src="/assets/images/logo_blue.svg" alt="" class="img-fluid mx-5 py-3">
                </router-link>
                <MenuComponent />
            </div>
        </div>
    </header><!-- End Header -->
</template>

<script>
import { useStore } from 'vuex'
//import { useRoute } from 'vue-router'
import { computed } from 'vue'
import MenuComponent from './MenuComponent.vue'
export default{
    name: 'Header',
    components: {
        MenuComponent
    },
    setup(){
        const store = useStore()
        //const currentRoute = computed(() => useRoute().name)
        const currentRoute = computed(() => {
            const routeMeta = store.state.routeMeta;
            return routeMeta && routeMeta.module ? routeMeta.module : null
        })
        return {
            currentRoute
        }
    }
}
</script>
