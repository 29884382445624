<template>
  <div v-for="item_noticia of noticias" v-bind:key="item_noticia.id_noticia" class="card mb-5 mt-0">
    <div class="backgroundEffect"></div> 
    <div class="row g-0">
      <div class="col-lg-4 pic">
        <router-link :to="{ name: 'novedad', params: { id: item_noticia.id_noticia }}">
          <img v-if="item_noticia.imagen" width="450" height="250" :src="'/images/news/'+item_noticia.imagen" class="img-fluid card-img-top left">
          <img v-else width="450" height="250" src="/assets/images/novedades/bg-novedades.jpg" class="img-fluid card-img-top left">
        </router-link>
        <div class="date">
          <span class="day">{{ getDay(item_noticia.pubdate) }}</span>
          <span class="month">
            <MesComponent :date="item_noticia.pubdate"/>
          </span>
          <span class="year">{{  getYear(item_noticia.pubdate) }}</span>
        </div>
      </div>
      <div class="col-lg-8">
        <div class="card-body content d-flex flex-column h-100">
          <div class="text-start mb-auto h-100">
            <router-link :to="{ name: 'novedad', params: { id: item_noticia.id_noticia }}" class="d-block h-100">
              <h5 class="card-title h-1 mt-4">{{ item_noticia.titulo }}</h5>
              <p class="card-text text-muted mt-1">{{ truncate(item_noticia.texto, 200) }}</p>
            </router-link>
          </div>
          <div class="d-flex justify-content-between align-items-end mb-3">
            <span>
              <span class="badge bg-gray px-2 py-1 shadow-1-strong bg-light text-dark me-1">{{ item_noticia.area }}</span>
              <span class="badge bg-gray px-2 py-1 shadow-1-strong bg-light text-dark">{{ item_noticia.categoria }}</span>
            </span>
            <!-- router-link :to="{ name: 'novedad', params: { id: item_noticia.id_noticia }}">
              <div class="btn btn-primary">Leer más...<span class="fas fa-arrow-right"></span></div> 
            </router-link -->
          </div>
        </div>
      </div>
    
    </div>
  </div>
</template>

<script>
import { getDay, getYear, truncate } from '../../utils/common.utils.js'
import MesComponent from '../general/MesComponent.vue'

export default{
  name: 'NoticiasDestacadasComponent',
  components:{
    MesComponent
  },
  props: {
    'noticias':{
      type: Array
    }
  },
  setup(){
    return {
      getDay,
      getYear,
      truncate
    }
  }
}
</script>
