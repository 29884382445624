<template>
  <div class="row row-cols-1 g-4 noticias mb-5" :class="thumbnails ? ['row-cols-sm-1','row-cols-md-2','row-cols-lg-3'] : 'event-list'">
    <div v-for="item_noticia of noticias" class="col noticias mb-3">
      <router-link :to="{ name: module ? module : 'novedad', params: { id: item_noticia.id_noticia }, query: filterArray($route.query, 'page') }">
        <div class="card h-100">
          <div class="pic"> 
            <img v-if="item_noticia.imagen" class="card-img-top" width="440" height="150" :src="'/images/news/'+getYear(item_noticia.created_at)+'/'+item_noticia.id_noticia+'/'+item_noticia.imagen" alt="">
            <img v-else width="450" height="250" src="/assets/images/novedades/bg-novedades.jpg" class="img-fluid card-img-top left">
          </div>
          <h5 class="card-title h-1 mt-2 text-center text-uppercase">{{ item_noticia.titulo ? item_noticia.titulo : '&nbsp;' }}</h5>
          <p class="card-text text-center">{{ truncate(item_noticia.texto, 100) }}</p>
        </div>
      </router-link>
    </div>
  </div>								
</template>

<script>
import { useRoute, useRouter } from 'vue-router'
import { getDay, getYear, truncate } from '../../utils/common.utils.js'
import MesComponent from '../general/MesComponent.vue'

export default{
  name: 'NoticiasDestacadasComponent',
  components:{
    MesComponent
  },
  props: {
    'noticias':{
      type: Array
    },
    'thumbnails': {
      type: Boolean,
      default: true
    },
    'showText':{
      type: Boolean,
      default: true
    },
    'query':{
      type: Object,
      default: {}
    },
    'module':{
      type: String
    }
  },
  setup(props){

    const route = useRoute()
    const router = useRouter()

    const filterArray = (array, propName) => {
      let newQuery = props.query
      array = { ...array, ...newQuery }
      const { [propName]: excluded, ...rest} = array
      return rest
    }

    return {
      filterArray,
      getDay,
      getYear,
      truncate
    }
  }
}
</script>

<style scoped>
 
</style>