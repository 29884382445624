import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import store from '../store'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: {
      requiresAuth: true, // Indicates that this route requires authentication
    }
  },
  {
    path: '/institucional',
    name: 'institucional',
    meta: { module: 'institucional' },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/InstitucionalView.vue')
  },
  {
    path: '/contacto',
    name: 'contacto',
    meta: { module: 'contacto' },
    component: () => import(/* webpackChunkName: "about" */ '../views/ContactoView.vue')
  },
  {
    path: '/nivel_inicial',
    name: 'nivel_inicial',
    meta: { module: 'nivel_inicial' },
    component: () => import(/* webpackChunkName: "about" */ '../views/NivelInicialView.vue')
  },
  {
    path: '/nivel_primario',
    name: 'nivel_primario',
    meta: { module: 'nivel_primario' },
    component: () => import(/* webpackChunkName: "about" */ '../views/NivelPrimarioView.vue')
  },
  {
    path: '/nivel_secundario',
    name: 'nivel_secundario',
    meta: { module: 'nivel_secundario' },
    component: () => import(/* webpackChunkName: "about" */ '../views/NivelSecundarioView.vue')
  },
  {
    path: '/pastoral',
    name: 'pastoral',
    meta: { module: 'pastoral' },
    component: () => import(/* webpackChunkName: "about" */ '../views/PastoralView.vue')
  },
  {
    path: '/pastoral/diasimportantes/',
    name: 'pastoral_diasimportantes',
    meta: { module: 'pastoral/diasimportantes' },
    component: () => import(/* webpackChunkName: "about" */ '../views/PastoralDiasImportantesView.vue')
  },
  {
    path: '/pastoral/alpha/',
    name: 'pastoral/alpha',
    meta: { module: 'pastoral/alpha' },
    component: () => import(/* webpackChunkName: "about" */ '../views/PastoralAlphaView.vue')
  },
  {
    path: '/pastoral/preju/',
    name: 'pastoral/preju',
    meta: { module: 'pastoral/preju' },
    component: () => import(/* webpackChunkName: "about" */ '../views/PastoralPrejuView.vue')
  },
  {
    path: '/pastoral/catequesis/',
    name: 'pastoral/catequesis',
    meta: { module: 'pastoral/catequesis' },
    component: () => import(/* webpackChunkName: "about" */ '../views/PastoralCatequesisView.vue')
  },
  {
    path: '/pastoral/novedades/',
    name: 'pastoral/novedades',
    meta: { module: 'pastoral/novedades' },
    component: () => import(/* webpackChunkName: "about" */ '../views/PastoralNovedadesView.vue')
  },
  {
    path: '/pastoral/novedades/:id?',
    name: 'pastoral/novedades',
    meta: { module: 'pastoral/novedades' },
    component: () => import(/* webpackChunkName: "about" */ '../views/PastoralNovedadesView.vue')
  },
  {
    path: '/novedades',
    name: 'novedades',
    meta: { module: 'novedades' },
    component: () => import(/* webpackChunkName: "about" */ '../views/NovedadesView.vue')
  },
  {
    path: '/novedades/:id?',
    name: 'novedad',
    meta: { module: 'novedades' },
    component: () => import(/* webpackChunkName: "about" */ '../views/NovedadesView.vue')
  },
  {
    path: '/eventos',
    name: 'eventos',
    meta: { module: 'eventos' },
    component: () => import(/* webpackChunkName: "about" */ '../views/EventosView.vue')
  },
  {
    path: '/eventos/:id?',
    name: 'evento',
    meta: { module: 'eventos' },
    component: () => import(/* webpackChunkName: "about" */ '../views/EventosView.vue')
  },
  {
    path: '/proyecto',
    name: 'proyecto',
    meta: { module: 'proyecto' },
    component: () => import(/* webpackChunkName: "about" */ '../views/ProyectoEducativoView.vue')
  },
  {
    path: '/admisiones',
    name: 'admisiones',
    meta: { module: 'admisiones' },
    component: () => import(/* webpackChunkName: "about" */ '../views/AdmisionesView.vue')
  },
  {
    path: '/administracion/inicio',
    name: 'administracion',
    meta: { module: 'administracion' },
    component: () => import(/* webpackChunkName: "about" */ '../views/AdministracionView.vue')
  },
  {
    path: '/administracion/faq',
    name: 'administracion-faq',
    meta: { module: 'administracion' },
    component: () => import(/* webpackChunkName: "about" */ '../views/AdministracionFaqView.vue')
  },
  {
    path: '/autogestion',
    name: 'autogestion',
    meta: { module: 'autogestion' },
    component: () => import(/* webpackChunkName: "about" */ '../views/AutogestionView.vue')
  },
  {
    path: '/administracion/actividades_extraprogramaticas',
    name: 'activiadades-extra',
    meta: { module: 'administracion/actividades_extraprogramaticas' },
    component: () => import(/* webpackChunkName: "about" */ '../views/ActividadesExtraprogramaticasView.vue')
  },
  {
    path: '/cv',
    name: 'cv',
    meta: { module: 'cv' },
    component: () => import(/* webpackChunkName: "about" */ '../views/EnviaCurriculumView.vue')
  }

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  // Store route metadata in Vuex store
  store.dispatch('setRouteMeta', to.meta)
  next()
})

export default router
