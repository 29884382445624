<template>
    <a :href="href" :class="(hollow ? 'btn-hollow' : 'btn-general') + ' align-middle'" :target="blank ? '_BLANK' : ''">{{ caption }}</a>
</template>

<script>

export default{
    name: 'ButtonComponent',
    props: {
        'caption':{
            type: String
        },
        'href':{
            type: String
        },
        'hollow':{
            type: Boolean,
            default: false
        },
        'blank':{
            type: Boolean,
            default: false
        }
    }
}
</script>