<template>
    <div :v-if="src">
        <iframe :style="'border:0; width: '+ (isNumeric(width) ? width + 'px': width) +'; height: ' + (isNumeric(height) ? height + 'px': height) + ';margin-bottom: -6px;'" :src="src" frameborder="0" allowfullscreen></iframe>
    </div>
</template>

<script>

export default{
    name: 'GoogleMapsComponent',
    props: {
        'src':{
            type: String
        },
        'height':{
            type: String,
            default: 350
        },
        'width':{
            type: Number,
            default: '100%'
        }
    },
    setup(){
        const isNumeric = (str) => {
            if (typeof(str) == "number") return true
            if (typeof str != "string") return false // we only process strings!  
            return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
                    !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
        }
        return { isNumeric }
    }
}
</script>