<template>
    <footer id="footer">
        <div class="container" v-if="datos_site">
            <div class="row">
                <div class="col-md-4">
                    <div class="px-5 mx-3 my-2">
                        <img class="img-fluid bg-white" src="/assets/images/logo_blue.svg" />
                        
                    </div>
                    <p v-if="datos_site.footer_texto" class="px-4 mt-5">{{ datos_site.footer_texto }}</p>
                </div>
                <div class="col-md-4">
                    <div class="middle-column d-flex flex-column">
                        <div class="p-2 text-white text-start">
                            <div>ACCESOS RÁPIDOS</div>
                            <ul>
                                <li v-for="item_acceso of datos_accesos" v-bind:key="item_acceso.id_acceso">
                                    <a class="text-white" :href="isURL(item_acceso.link) ? item_acceso.link : '/'+item_acceso.link"  :target="isURL(item_acceso.link) ? '_BLANK' : ''" >{{ item_acceso.titulo }}</a>
                                </li>
                            </ul>
                        </div>
                        <div class="social-links p-2 mt-auto text-white">
                            <a v-if="datos_site.footer_link_twitter" :href="datos_site.footer_link_twitter" class="twitter" target="_blank">
                                <i class="bx bxl-twitter"></i>
                            </a>
                            <a v-if="datos_site.footer_link_facebook" :href="datos_site.footer_link_facebook" class="facebook" target="_blank">
                                <i class="bx bxl-facebook"></i>
                            </a>
                            <a v-if="datos_site.footer_link_instagram" :href="datos_site.footer_link_instagram" class="instagram" target="_blank">
                                <i class="bx bxl-instagram"></i>
                            </a>
                            <a v-if="datos_site.footer_link_linkedin" :href="datos_site.footer_link_linkedin" class="linkedin" target="_blank">
                                <i class="bx bxl-linkedin"></i>
                            </a>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="px-3">
                        <GoogleMapsComponent v-if="datos_site?.map" :src="datos_site.map" height="220px"/>
                    </div>
                    <div class="direccion py-3 text-start" v-if="datos?.direccion" v-html="nlToBr(datos.direccion)"></div>
                </div>
            </div>
        </div>
    </footer>
    
</template>

<script>
import { useStore } from 'vuex'
import { computed } from 'vue'
import { nlToBr } from '../utils/common.utils.js'
import GoogleMapsComponent from '../components/general/GoogleMapsComponent.vue'

export default{
    name: 'FooterComponent',
    components: {
        GoogleMapsComponent
    },
    setup(){
        const store = useStore()
        const datos_site = computed(() => store.state.datos.site)
        const datos_accesos = computed(() => store.state.datos.accesos)
        const datos = computed(() => store.state.datos.data)

        const isURL = (str) => {
            return (str.indexOf("http://") == 0 || str.indexOf("https://") == 0)
        }
        return {datos_site, datos_accesos, datos, isURL, nlToBr}
    }
}
</script>

<style scoped>
    .middle-column {
        height: 100%;
        font-size: 1.2em;
        font-weight: bold;
        font-family: "Montserrat-Bold";
    }

    .middle-column a {
        font-family: "Montserrat";
        font-size: .9em;
    }
    .direccion {
        font-weight: bold;
        font-family: "Montserrat";
        font-size: 1em;
    }
</style>