<template>
    <div :class="class" class="section-title pb-0">
        <h2>{{ title }}</h2>
    </div>
</template>

<script>
export default{
    name: 'TitleComponent',
    props: {
        'title':{
            type: String,
            default: 'Sin titulo'
        },
        'class':{
            type: String
        }
    }
}
</script>

<style scoped>
h2{
    font-family: "Montserrat-Bold" !important;
    text-transform: none;
    width: 35vw;
    margin: 0 auto;
    font-size: 2em;
    color: #113161;
    text-transform: uppercase;
}
@media (max-width: 1024px) {
    h2{
        width: auto;
    } 
}
</style>